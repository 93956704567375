<!--困难生认定-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">单项积极分子审批</div>
    <!--=====================================工具栏========================================-->
    <div class="page-tool">
      <div style="width: 80%">
        <el-row :gutter="20">
          <el-col :span="6">
            <span class="mr5 fcolor42">年份:</span>
            <el-select v-model="xn" size="small" clearable>
              <el-option
                v-for="(item, index) in xnList"
                :key="index"
                :label="item.ksnf + '-' + item.jsnf + '学年'"
                :value="item.ksnf + '-' + item.jsnf + '学年'"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5">系部:</span>
            <el-select v-model="bmbh" size="small" clearable @change="bmchange">
              <el-option
                v-for="(item, index) in bmlist"
                :key="index"
                :label="item.bmmc"
                :value="item.bmbh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="fcolor42 mr5">专业:</span>
            <el-select v-model="zybh" size="small" clearable @change="zychange">
              <el-option
                v-for="(item, index) in zylist"
                :key="index"
                :label="item.zymc"
                :value="item.zybh"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="6" style="display: flex; align-items: center">
            <span class="mr5 fcolor42">班级:</span>
            <el-select v-model="bjbh" size="small" clearable>
              <el-option
                v-for="(item, index) in bjlist"
                :key="index"
                :label="item.bjmc"
                :value="item.bjbh"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col>
            <span class="fcolor42 mr5">学号/姓名:</span>
            <el-input
              v-model="keyword"
              placeholder="请输入学号/姓名"
              size="small"
              style="width: 200px"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataPageList"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <el-button-group>
        <el-button
          size="small"
          icon="el-icon-refresh"
          @click="reload()"
        ></el-button>
        <el-button
          size="small"
          v-if="hasPerms('sy_sp')"
          icon="el-icon-delete"
          @click="detailInfo"
          >审批</el-button
        >
      </el-button-group>
    </div>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号"> </el-table-column>
        <el-table-column prop="xm" label="姓名"> </el-table-column>
        <el-table-column prop="xbm" label="性别">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="bjmc" label="班级"> </el-table-column>
        <el-table-column prop="xn" label="学年"> </el-table-column>
        <el-table-column prop="rych" label="荣誉称号"> </el-table-column>
        <el-table-column prop="zt" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.spzt === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 110" type="success"
              >院系审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 120" type="danger"
              >院系审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 111" type="success"
              >学工审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 112" type="danger"
              >学工审核拒绝</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="单项积极分子"
      :visible.sync="dialogFormVisible"
      width="70%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 700px" class="myscrollbar">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in forminfo.spjl"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                院系审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                学工审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form
          :model="spinfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-card class="box-card">
            <span class="staffBmTitle">“单项积极分子”申请审批表</span>
            <table border="1px" class="staffBmTable">
              <tr>
                <td style="width: 100px">姓名</td>
                <td>
                  {{ forminfo.XM }}
                </td>
                <td style="width: 100px">性别</td>
                <td style="width: 100px">
                  {{ forminfo.XB }}
                </td>
                <td style="width: 100px">出生年月</td>
                <td>
                  {{ forminfo.CSNY }}
                </td>
                <td style="width: 100px">民族</td>
                <td>
                  {{ forminfo.MZ }}
                </td>
              </tr>
              <tr>
                <td>所在班级</td>
                <td colspan="3">
                  {{ forminfo.BJMC }}
                </td>
                <td>学号</td>
                <td>
                  {{ forminfo.XH }}
                </td>
                <td>所在宿舍</td>
                <td>
                  {{ forminfo.FJBH }}
                </td>
              </tr>
              <tr>
                <td>政治面貌</td>
                <td colspan="3">
                  {{ forminfo.ZZMM }}
                </td>
                <td>担任职务</td>
                <td colspan="3">
                  {{ forminfo.DRZW }}
                </td>
              </tr>
              <tr>
                <td style="width: 30px">荣誉称号</td>
                <td colspan="7">
                  {{ forminfo.RYCH }}
                </td>
              </tr>
              <tr v-if="forminfo.SPZT == 0">
                <td style="width: 30px">星级宿舍评比</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="XJSS"
                  >
                    <el-radio-group v-model="spinfo.XJSS">
                      <el-radio label="优秀">优秀</el-radio>
                      <el-radio label="良好">良好</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
                <td style="width: 30px">综合测评</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="ZHCP"
                  >
                    <el-radio-group v-model="spinfo.ZHCP">
                      <el-radio label="优秀">优秀</el-radio>
                      <el-radio label="良好">良好</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
              </tr>
            </table>
          </el-card>

          <div class="form-item-group mt10">
            <i class="fa fa-window-restore"></i>
            <div>审批</div>
          </div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批意见" label-width="120px" prop="SPYJ">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="请输入内容"
                  v-model="spinfo.SPYJ"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="审批" label-width="120px" prop="SPZT">
                <el-radio v-model="spinfo.SPZT" :label="1">审批通过</el-radio>
                <el-radio v-model="spinfo.SPZT" :label="2">审批拒绝</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-scrollbar>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getDXDSPPageList, saveDXSP, getDXSQFormData } from '../../api/py'
import { GetListByQX, GetZYListByQX, GetBJListByQX } from '../../api/jcxx'
import {
  getAuthorizeButtonColumnList,
  getXNList,
  getDQXQ,
} from '../../api/system'
import { baseUrl } from '../../utils/http'
import moment from 'moment'
export default {
  data() {
    return {
      downurl: baseUrl,
      fileList: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 100, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      operation: 1, // 当前操作1=添加，0=编辑
      splist: [], // 审批记录
      xsinfo: {},
      bmlist: [],
      zylist: [],
      bjlist: [], // 班级列表
      bmbh: '',
      zybh: '',
      bjbh: '',
      xh: '',
      xn: '',
      xnList: [],
      forminfo: {
        FJBH: '',
        LXDH: '',
        JTDZ: '',
        BJMC: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        MZ: '',
        ZZMM: '',
        JG: '',
        STEP: 0,
        SPZT: null,
        DRZW: '',
        RYCH: null,
        XJSS: null,
        ZHCP: null,
        fj: [],
      },
      spinfo: {
        SPYJ: '',
        SPZT: 1,
        ZHCP: '',
        XJSS: null,
      },
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailFormVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        XJSS: [
          {
            required: true,
            message: '请选择星级宿舍评比结果',
            trigger: 'blur',
          },
        ],
        ZHCP: [{ required: true, message: '请填写综合测评', trigger: 'blur' }],
        SPYJ: [{ required: true, message: '请填写审批意见', trigger: 'blur' }],
        SPZT: [{ required: true, message: '请审批', trigger: 'blur' }],
      },
    }
  },
  created() {
    GetListByQX().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getXNList().then((res) => {
      if (res.code === 200) {
        this.xnList = res.data
        getDQXQ()
          .then((re) => {
            if (re.code === 200) {
              this.xn = re.data.xnmc
            }
            this.getDataPageList()
          })
          .catch((err) => {
            console.log(err)
            this.xnList = []
            this.getDataPageList()
          })
      }
    })
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 保存信息
    saveForm() {
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          if (this.forminfo.SPZT === 0) {
            saveDXSP({
              SQID: this.keyValue,
              SPZT: this.spinfo.SPZT,
              SPYJ: this.spinfo.SPYJ,
              XJSS: this.spinfo.XJSS,
              ZHCP: this.spinfo.ZHCP,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.dialogFormVisible = false
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            saveDXSP({
              SQID: this.keyValue,
              SPZT: this.spinfo.SPZT,
              SPYJ: this.spinfo.SPYJ,
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success(res.message)
                this.dialogFormVisible = false
                this.getDataPageList()
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },
    // 显示编辑弹出框
    detailInfo() {
      if (this.keyValue === '') {
        this.$message.error('您没有选中任何数据项，请选中后在操作！')
        return 0
      } else {
        this.operation = 0
        getDXSQFormData({ id: this.keyValue }).then((res) => {
          if (res.code === 200) {
            this.splist = res.data.spjl
            this.forminfo.XH = res.data.xh
            this.forminfo.XM = res.data.xm
            this.forminfo.XB = res.data.xb === '1' ? '男' : '女'
            this.forminfo.CSNY = this.dateFormat3(res.data.csny)
            this.forminfo.JTDZ = res.data.jtdz
            this.forminfo.LXDH = res.data.lxdh
            // this.forminfo.RXSJ = this.dateFormat4(res.data.rxsj)
            this.forminfo.MZ = res.data.mz
            this.forminfo.ZZMM = res.data.zzmm
            this.forminfo.BJMC = res.data.bjmc
            this.forminfo.JG = res.data.jg
            this.forminfo.FJBH = res.data.fjbh
            this.forminfo.RYCH = res.data.rych
            this.forminfo.DRZW = res.data.drzw
            this.forminfo.XJSS = res.data.xjss
            this.forminfo.ZHCP = res.data.zhcp
            this.forminfo.SPZT = res.data.spzt
            this.forminfo.spjl = res.data.spjl
            this.dialogFormVisible = true
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
        this.xh = ''
      } else {
        this.keyValue = currentRow.id
        this.xh = currentRow.xh
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getDXDSPPageList({
        queryJson: JSON.stringify({
          keyword: this.keyword,
          XN: this.xn,
          BMBH: this.bmbh,
          ZYBH: this.zybh,
          BJBH: this.bjbh,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bmbh }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zybh }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zybh = ''
      this.bjbh = ''
      this.getZY()
    },
    zychange() {
      this.bjbh = ''
      this.getBJ()
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.page-tool {
  padding: 20px;
  width: 100%;
  height: 120px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.page-tool .el-input {
  width: 300px;
}

.page-tool .el-button {
  margin-left: 10px;
}
.c_table {
  height: 100%;
  overflow-y: auto;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
